export const routes = {
  agreement: 'agreement',
  auth: 'auth',
  celebrate: 'celebrate',
  root: '/',
  login: '/login',
  original: '/original',
  policy: '/policy',
  pricing: '/pricing',
  redactor: '/redactor',
  support: '/support',
}