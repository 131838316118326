import { ArrowPathIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { ReactNode } from 'react';

interface IProps {
  variant?: 'bordered' | 'secondary' | 'primary';
  className?: string;
  children: ReactNode;
  disabled?: boolean;
  isActive?: boolean;
  onClick?: () => void;
  isLoading?: boolean;
}

export default function Button({
  children,
  onClick,
  disabled = false,
  variant = 'primary',
  isActive,
  isLoading,
  className,
}: IProps) {
  if (variant === 'bordered')
    return (
      <button
        disabled={disabled}
        onClick={onClick}
        className={classNames(
          'text-center rounded-lg border-2 p-1',
          isActive ? 'border-slate-600' : 'border-slate-200',
          className
        )}
      >
        {children}
      </button>
    );

  if (variant === 'secondary')
    return (
      <button
        className={classNames(
          'w-full py-1 px-2 text-center rounded-lg bg-slate-200',
          className
        )}
        onClick={onClick}
      >
        {children}
      </button>
    );

  return (
    <button
      disabled={disabled}
      onClick={onClick}
      className={classNames(
        'w-full py-1 px-2 text-center rounded-lg bg-slate-600 text-white flex justify-center items-center space-x-3',
        isLoading && 'pointer-events-none',
        className
      )}
    >
      {isLoading && <ArrowPathIcon className="h-6 animate-spin" />}
      <span>{children}</span>
    </button>
  );
}
