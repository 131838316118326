import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { API_URL } from '../constants';

const getUser = () => {
  console.log(localStorage.getItem('token'));
  return axios
    .post(`${API_URL}/user`, undefined, {
      withCredentials: true,
      headers: { Authorization: localStorage.getItem('token') },
    })
    .then(({ data }) => data)
    .catch((err) => err?.response.data);
};

export function useUser() {
  return useQuery({
    queryKey: ['user'],
    queryFn: getUser,
  });
}

export function useLazyUser({ isEnabled = false } = {}) {
  return useQuery({
    queryKey: ['user'],
    queryFn: getUser,
    enabled: isEnabled,
  });
}
