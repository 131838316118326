import { useAuth } from '../providers/AuthProvider';
import Dialog from './dialogs/dialog';
import Login from './login';

export default function Main() {
  const { isAuthenticated } = useAuth();

  if (isAuthenticated) return <Dialog />;
  return <Login />;
}
