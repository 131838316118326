interface IProps {
  value?: any;
  onChange: (value: any) => void;
  className?: string;
  placeholder?: string;
  label?: string;
  type?: string;
}

export default function Input({
  onChange,
  value = '',
  className,
  label,
  placeholder,
  type,
}: IProps) {
  return (
    <div className={className}>
      {label && <p>{label}</p>}
      <input
        className="border w-full rounded-lg mt-2 px-2 py-1 outline-slate-100"
        placeholder={placeholder}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        type={type}
      />
    </div>
  );
}
