import { ReactNode } from 'react';

export default function Checkbox({
  label = '',
  id = '',
  value = false,
  className = '',
  onChange,
}: {
  label: ReactNode;
  id: string;
  value: boolean;
  className?: string;
  onChange: (v: boolean) => void;
}) {
  return (
    <div className={`flex items-center ${className} w-full`}>
      <input
        checked={value}
        id={id}
        type="checkbox"
        value=""
        onChange={(e) => onChange(e.target.checked)}
        className="w-4 h-4 text-slate-400 bg-gray-100 border-gray-300 rounded "
      />
      <label htmlFor={id} className="ms-2  font-medium text-gray-900 leading-0">
        {label}
      </label>
    </div>
  );
}
