import React, {
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useLazyUser } from '../hooks/useUser';
import useBalance from '../hooks/useBalance';

interface IContextValue {
  isAuthenticated: boolean;
  isLoading: boolean;
  balance: number;
  onPay: (cost?: number) => void;
  isLocked: boolean;
  setIsAuthenticated: (val: boolean) => void;
}

const AuthContext = React.createContext<IContextValue>({
  isAuthenticated: false,
  isLoading: true,
  balance: 0,
  onPay: () => null,
  isLocked: false,
  setIsAuthenticated: () => null,
});

export const useAuth = () => {
  return useContext(AuthContext);
};

export default function AuthProvider({ children }: PropsWithChildren) {
  const { refetch, isRefetching, isFetched, isFetching } = useLazyUser();
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const isAuthenticated = localStorage.getItem('isAuthenticated') === 'true';
    setIsAuthenticated(isAuthenticated);
  }, [isAuthenticated]);

  useEffect(() => {
    async function check() {
      if (isFetched) return;
      const { data } = await refetch();
      if (!data.success) {
        // localStorage.removeItem('isAuthenticated');
        // localStorage.removeItem('token');
      } else {
        localStorage.setItem('isAuthenticated', 'true');
      }
    }
    check();
  }, [isAuthenticated, isFetched, isRefetching, refetch]);

  const { balance, onPay } = useBalance({ isAuthenticated });

  const isLocked = useMemo(() => {
    return balance <= 0;
  }, [balance]);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: isAuthenticated,
        isLoading: isRefetching || isFetching,
        balance,
        onPay,
        isLocked,
        setIsAuthenticated,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
