import classNames from 'classnames';
import { HiPaperAirplane } from 'react-icons/hi';
import { FaUserCircle } from 'react-icons/fa';
import { BetaMessage } from '../../components/BetaMessage';
import { Link } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { useDialog } from '../../hooks/useDialog';
import Markdown from 'react-markdown';
import Button from '../../components/atoms/button';
import { useClearDialog } from '../../hooks/useClearDialog';
import Input from '../../components/atoms/input';

const scroll = () => {
  document.getElementById('chat')?.scrollTo({
    top: document.getElementById('chat')?.scrollHeight ?? 0,
  });
};

export default function Dialog() {
  const { data, refetch } = useDialog();
  const [prompt, setPrompt] = useState('');
  const [promptCopy, setPromptCopy] = useState('');

  useEffect(() => {
    scroll();
  }, [data]);

  const [completion, setCompletion] = useState('');
  const [isCompleting, setIsCompleting] = useState(false);
  const [count, setCount] = useState(10);

  const onSend = useCallback(async () => {
    setPromptCopy(prompt);
    setPrompt('');
    setIsCompleting(true);

    const response = await fetch('http://localhost:3001/ai/chat', {
      method: 'POST',
      signal: AbortSignal.timeout(1000000),
      body: JSON.stringify({ prompt, count }),
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('token') ?? '',
      },
      credentials: 'include',
    });

    const reader = response?.body?.getReader();

    if (!reader) return;

    while (true) {
      const { done, value } = await reader.read();
      const text = new TextDecoder().decode(value);
      setCompletion((prev) => `${prev}${text}`);
      scroll();
      if (done) {
        await refetch();
        setIsCompleting(false);
        setCompletion('');
        setPromptCopy('');
        break;
      }
    }
  }, [count, prompt, refetch]);

  const clearDialog = useClearDialog();

  return (
    <main className={`flex justify-center h-screen bg-slate-100`}>
      <div className="max-w-4xl w-full space-y-4 flex flex-col h-screen p-4">
        <div
          className={classNames(
            'flex space-x-4 justify-between items-center relative'
          )}
        >
          <div className="flex space-x-1 items-center ">
            <Button
              variant="secondary"
              onClick={clearDialog.mutate}
              isLoading={clearDialog.isPending}
            >
              Очистить диалог
            </Button>
            <div className="absolute right-12">
              <div className=" bg-amber-100 px-2 py-0.5 rounded">
                Обратная связь
              </div>
            </div>
          </div>
          <Link to="/user">
            <FaUserCircle className="text-slate-300 w-7 h-7 " />
          </Link>
        </div>
        <BetaMessage />
        <div
          id="chat"
          className={classNames(
            'rounded-xl shadow-xl shadow-slate-500/10 w-full bg-white',
            'flex-1 overflow-y-scroll'
          )}
        >
          {!data?.messages.length && !completion && (
            <div className="m-4 space-y-4">
              <div className="text-lg font-bold">
                Я чат-бот на базе GPT-4o, и много чего умею, например:
              </div>
              <div className="p-3 border rounded-xl bg-slate-50">
                Напиши эссе на тему 'Влияние интернета на общественные
                коммуникации'.
              </div>
              <div className="p-3 border rounded-xl bg-slate-50">
                Составь отчет о текущих рыночных тенденциях в индустрии
                мобильных приложений.
              </div>
              <div className="p-3 border rounded-xl bg-slate-50">
                Создай упражнения для улучшения навыков письма на английском
                языке для среднего уровня.
              </div>
              <div className="p-3 border rounded-xl bg-slate-50">
                Составь план личного развития на ближайший месяц для достижения
                цели 'улучшение физической формы'.
              </div>
              <div className="p-3 border rounded-xl bg-slate-50">
                Составь план мероприятия для празднования дня рождения на 20
                человек.
              </div>
            </div>
          )}
          {data?.messages.map(
            ({ body = {}, id = '' }: { body: any; id: string }) => (
              <div
                key={`complete-${id}`}
                className={classNames(
                  'p-4 border-b',
                  body.role === 'assistant' ? 'bg-white' : 'bg-slate-50'
                )}
              >
                <div className="flex space-x-4">
                  <div className="space-y-2 prose prose-lg">
                    <Markdown>{body?.content}</Markdown>
                  </div>
                </div>
              </div>
            )
          )}
          {isCompleting && (
            <>
              <div className={classNames('p-4 border-b', 'bg-slate-50')}>
                <div className="flex space-x-4">
                  <div className="space-y-2 prose prose-lg">
                    <Markdown>{promptCopy}</Markdown>
                  </div>
                </div>
              </div>
              <div className={classNames('p-4 border-b', 'bg-white')}>
                <div className="flex space-x-4">
                  <div className="space-y-2 prose prose-lg">
                    <Markdown>{completion}</Markdown>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        <div
          className={classNames(
            'rounded-xl shadow-xl shadow-slate-500/10 p-4 w-full bg-white',
            'flex space-x-4 items-end'
          )}
        >
          <textarea
            placeholder="Сообщение тут"
            rows={2}
            className="w-full outline-none resize-none text-lg"
            value={prompt}
            onChange={(e) => {
              setPrompt(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && (e.ctrlKey || e.metaKey) && !!prompt) {
                onSend();
              }
            }}
          />
          <HiPaperAirplane
            onClick={onSend}
            className="text-slate-400 w-8 h-8 rotate-90 cursor-pointer"
          />
        </div>
        <div className="flex items-center space-x-4">
          <div>Сколько последних сообщений запомнить?</div>
          <Input type="number" value={count} onChange={setCount} />
        </div>
      </div>
    </main>
  );
}
