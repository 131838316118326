import classNames from 'classnames';
import { FaUserCircle } from 'react-icons/fa';
import { BetaMessage } from '../../components/BetaMessage';
import { Link } from 'react-router-dom';
import { useAuth } from '../../providers/AuthProvider';
import { useLazyUser } from '../../hooks/useUser';

export default function User() {
  const { isAuthenticated } = useAuth();
  const { data } = useLazyUser({ isEnabled: isAuthenticated });

  return (
    <main className={`flex justify-center h-screen  bg-slate-50 `}>
      <div className="max-w-xl w-full space-y-4 flex flex-col h-screen p-4">
        <div
          className={classNames(
            'flex space-x-4 justify-between items-center relative'
          )}
        >
          <div className="flex space-x-1 items-center ">
            <Link to="/">
              <div className="text-slate-500">Назад</div>
            </Link>
            <div className="absolute right-12">
              <div className=" bg-amber-100 px-2 py-0.5 rounded">
                Обратная связь
              </div>
            </div>
          </div>
          <FaUserCircle className="text-slate-300 w-7 h-7 " />
        </div>
        <BetaMessage />
        <div className="space-y-2 flex flex-col">
          <div
            className={classNames(
              'rounded-xl shadow-xl shadow-slate-500/10 p-4 w-full bg-white',
              'flex space-x-4 items-end'
            )}
          >
            <div className="flex items-center space-x-4">
              <FaUserCircle className="text-slate-300 w-7 h-7 " />
              <div>{data?.user?.email}</div>
            </div>
          </div>
          <button className="rounded-lg bg-slate-600 text-white p-2 w-full">
            Поддержка
          </button>
        </div>
      </div>
    </main>
  );
}
