import { useEffect, useState } from 'react';
import { IoCloseOutline } from 'react-icons/io5';

export const BetaMessage = () => {
  const [isHidden, setIsHidden] = useState(true);
  useEffect(() => {
    const isHidden = localStorage.getItem('BETA_HIDDEN') === 'true';
    setIsHidden(isHidden);
  }, []);

  if (isHidden) return null;
  return (
    <div className="rounded-xl shadow-xl shadow-slate-500/10 p-4 w-full bg-amber-100">
      <div className="text-lg w-full flex justify-between font-bold">
        <span>Ранний доступ</span>
        <IoCloseOutline
          onClick={() => {
            setIsHidden(true);
            localStorage.setItem('BETA_HIDDEN', 'true');
          }}
          className="w-5 h-5"
        />
      </div>
      <div className="">Спасибо, что присоединились к раннему доступу.</div>
      <div className="">
        Если возникнут проблемы, пожалуйста,{' '}
        <span className="underline">кликните на обратную связь</span>
      </div>
    </div>
  );
};
