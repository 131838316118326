import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { API_URL } from '../constants';

const confirmOTPRequest = ({ otp = '' }) => {
  return axios
    .post(`${API_URL}/otp/compare`, { otp }, { withCredentials: true })
    .catch((err) => err?.response);
};

export function useConfirmOTP() {
  return useMutation({
    mutationFn: confirmOTPRequest,
  });
}
