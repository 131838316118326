import { createHashRouter, RouterProvider } from 'react-router-dom';
import Dialog from './pages/dialogs/dialog';
import User from './pages/user/user';
import AuthProvider from './providers/AuthProvider';
import Login from './pages/login';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import Main from './pages/main';

const router = createHashRouter([
  {
    path: '/',
    element: <Main />,
  },
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '/user',
    element: <User />,
  },
]);

const queryClient = new QueryClient();

export default function App() {
  return (
    <div>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <RouterProvider router={router} />
        </AuthProvider>
      </QueryClientProvider>
    </div>
  );
}
