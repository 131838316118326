// import { captureMessage } from '@sentry/nextjs';
import { useCallback, useState } from 'react';
import OtpInput from 'react-otp-input';
import { useAuth } from '../providers/AuthProvider';
import { useSendOTP } from '../hooks/useSendOTP';
import { useConfirmOTP } from '../hooks/useConfirmOTP';
import Button from '../components/atoms/button';
import { Link } from 'react-router-dom';
import { routes } from '../utils/routes';
import Checkbox from '../components/atoms/checkbox';
import Input from '../components/atoms/input';

Login.defaultProps = {
  hasLayout: false,
};

export default function Login() {
  const { isAuthenticated, setIsAuthenticated } = useAuth();

  const [isSent, setIsSent] = useState(false);
  const [isAgreed, setIsAgreed] = useState(true);
  const [isAgreedEmail, setIsAgreedEmail] = useState(true);
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [codeError, setCodeError] = useState('');
  const [code, setCode] = useState('');

  const sendOTP = useSendOTP();
  const confirmOTP = useConfirmOTP();

  const onAuthenticate = useCallback(async () => {
    if (!isAgreed) {
      setError('Нужно согласиться');
      return;
    }
    try {
      const { data } = await sendOTP.mutateAsync({
        email,
        agreed: isAgreedEmail,
      });
      if (data.success) setIsSent(true);
      else setError('Что-то не так с почтой');

      //@ts-ignore
      ym(94732716, 'reachGoal', 'email-auth-click');
    } catch (err) {
      // captureMessage('Email login init failed', {
      //   extra: { err: JSON.stringify(err) },
      // });
      setError('Что-то не так с почтой');
    }
  }, [email, isAgreed, isAgreedEmail, sendOTP]);

  const onConfirm = useCallback(async () => {
    const { data } = await confirmOTP.mutateAsync({
      otp: code,
    });
    console.log({ data });
    if (data.success) {
      localStorage.setItem('token', data.accessToken);
      localStorage.setItem('isAuthenticated', 'true');
      setIsAuthenticated(true);
    } else setCodeError('Неверный код');
  }, [code, confirmOTP, setIsAuthenticated]);

  if (!isAuthenticated && isSent)
    return (
      <div className="flex fixed left-0 top-0 z-50 bg-slate-100 w-full pb-4 h-screen items-center p-3">
        <div className="max-w-sm bg-white px-3 w-full mx-auto justify-between space-y-2 mt-2 shadow-lg p-6 rounded-xl">
          <div className="w-full text-center pt-2">
            Отправили письмо c кодом на{' '}
            <span className="text-slate-600">{email}</span>
          </div>
          <div className="bg-slate-50 rounded-lg p-3 text-center ">
            Проверьте папку <span className="underline">спам</span>,
            <br /> оно могло попасть туда по ошибке
          </div>
          <div className="text-center pt-2">Введите код</div>
          <div className="w-full flex justify-center my-3 items-center">
            <OtpInput
              value={code}
              onChange={setCode}
              numInputs={5}
              shouldAutoFocus
              renderSeparator={<span className="w-4" />}
              renderInput={(props) => (
                <input
                  {...props}
                  type="tel"
                  className="border rounded-lg p-2 outline-slate-100"
                  style={{ width: '40px', textAlign: 'center' }}
                />
              )}
            />
          </div>
          {codeError && (
            <div className=" text-red-400 text-center">{codeError}</div>
          )}
          <div className="pt-2">
            <Button isLoading={confirmOTP.isPending} onClick={onConfirm}>
              Продолжить
            </Button>
          </div>
          <Link to="/">
            <button className="text-center w-full underline">назад</button>
          </Link>
        </div>
      </div>
    );

  return (
    <div className="flex fixed left-0 top-0 z-50 bg-slate-100 w-full pb-4 h-screen items-center p-3">
      <div className="max-w-sm border bg-white px-3 w-full mx-auto shadow-lg p-6 rounded-xl">
        <div className="w-full">
          <p>Введите почту</p>
          <div>
            <Input
              className="w-full"
              placeholder="email@mail.ru"
              value={email}
              onChange={setEmail}
            />
            <Checkbox
              id="policy"
              label={
                <span>
                  Согласен с{' '}
                  <Link className="underline" to={routes.policy}>
                    политикой конфиденциальности
                  </Link>{' '}
                  и{' '}
                  <Link className="underline" to={routes.agreement}>
                    пользовательским соглашением
                  </Link>
                </span>
              }
              value={isAgreed}
              className="mt-3"
              onChange={setIsAgreed}
            />
            <Checkbox
              id="ads"
              label="Хочу получить письмо, если мы придумаем что-то крутое"
              value={isAgreedEmail}
              className="mt-3"
              onChange={setIsAgreedEmail}
            />
          </div>
          {error && <div className=" text-red-400 absolute">{error}</div>}
        </div>
        <Button
          onClick={onAuthenticate}
          className="w-full mt-6"
          isLoading={sendOTP.isPending}
        >
          Подтвердить почту
        </Button>
      </div>
    </div>
  );
}
