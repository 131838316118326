import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { API_URL } from '../constants';

const decreaseBalanceRequest = ({ value = 0 }) => {
  return axios
    .post(
      `${API_URL}/user/decrease-balance`,
      { value },
      {
        withCredentials: true,
        headers: { Authorization: localStorage.getItem('token') },
      }
    )
    .catch((err) => err?.response);
};

export function useDecreaseBalance() {
  const client = useQueryClient();
  return useMutation({
    mutationFn: decreaseBalanceRequest,
    onSuccess: () => {
      client.invalidateQueries({ queryKey: ['user'] });
    },
  });
}
