import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { API_URL } from '../constants';

const clearDialog = () => {
  return axios
    .post(`${API_URL}/dialog/delete-messages`, undefined, {
      withCredentials: true,
      headers: { Authorization: localStorage.getItem('token') },
    })
    .then(({ data }) => data)
    .catch((err) => err?.response.data);
};

export function useClearDialog() {
  const client = useQueryClient();
  return useMutation({
    mutationFn: clearDialog,
    onSuccess: () => {
      client.invalidateQueries({ queryKey: ['dialog'] });
    },
  });
}
