import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { API_URL } from '../constants';

const sendOTPRequest = ({ email = '', agreed = false }) => {
  return axios
    .post(`${API_URL}/otp/create`, { email, agreed })
    .catch((err) => err?.response);
};

export function useSendOTP() {
  return useMutation({
    mutationFn: sendOTPRequest,
  });
}
