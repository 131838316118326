import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { API_URL } from '../constants';

const getDialog = () => {
  return axios
    .post(`${API_URL}/dialog`, undefined, {
      withCredentials: true,
      headers: { Authorization: localStorage.getItem('token') },
    })
    .then(({ data }) => data)
    .catch((err) => err?.response.data);
};

export function useDialog() {
  return useQuery({
    queryKey: ['dialog'],
    queryFn: getDialog,
  });
}
