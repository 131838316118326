import { useCallback, useEffect, useRef, useState } from 'react';
import { useLazyUser } from './useUser';
import { useDecreaseBalance } from './useDecreaseBalance';
import { balanceKey, defaultBalance } from '../constants';

const getLocalBalance = () => window.localStorage.getItem(balanceKey);
const setLocalBalance = (value: number) =>
  window.localStorage.setItem(balanceKey, JSON.stringify(value));

export default function useBalance({ isAuthenticated = false }) {
  const [currentBalance, setCurrentBalance] = useState(0);

  const { data, isLoading } = useLazyUser({
    isEnabled: isAuthenticated,
  });
  const decreaseBalance = useDecreaseBalance();

  useEffect(() => {
    if (isLoading) return;
    if (isAuthenticated) {
      setCurrentBalance(data?.user?.balance);
    } else {
      const localBalance = getLocalBalance();
      if (localBalance === null) {
        setLocalBalance(defaultBalance);
        setCurrentBalance(defaultBalance);
      } else setCurrentBalance(+localBalance);
    }
  }, [data?.user?.balance, isAuthenticated, isLoading]);

  const lastCost = useRef(1);

  const onPay = useCallback(
    (cost = 1) => {
      lastCost.current = cost;
      if (!isAuthenticated) {
        let balance: string | number | null = getLocalBalance();
        if (balance === null) {
          setLocalBalance(defaultBalance);
          balance = defaultBalance;
        }
        const newBalance = +(balance as string) - cost;
        setLocalBalance(newBalance);
        setCurrentBalance(newBalance);
      } else {
        decreaseBalance.mutate({ value: cost });
      }
    },
    [decreaseBalance, isAuthenticated]
  );

  return {
    balance: currentBalance,
    onPay,
  };
}
